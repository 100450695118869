<template>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M13.3 5.95h-.088c-1.137-.262-1.662-1.575-1.05-2.625l.088-.087c.175-.35.175-.788-.175-1.05l-.262-.263a.93.93 0 0 0-1.05-.175l-.088.088C9.625 2.45 8.4 1.925 8.05.788V.7C7.962.263 7.612 0 7.175 0h-.35c-.438 0-.788.263-.875.7v.088c-.263 1.137-1.575 1.662-2.625 1.05l-.088-.088a.93.93 0 0 0-1.05.175l-.262.263a.93.93 0 0 0-.175 1.05l.087.087C2.45 4.375 1.925 5.6.787 5.95H.7c-.437.088-.7.438-.7.875v.35c0 .438.263.788.7.875h.088c1.137.263 1.662 1.575 1.05 2.625l-.088.087c-.175.35-.175.788.175 1.05l.262.263a.93.93 0 0 0 1.05.175l.088-.088c1.05-.612 2.275-.087 2.625 1.05v.088c.087.35.438.7.875.7h.35c.438 0 .788-.262.875-.7v-.088c.262-1.137 1.575-1.662 2.625-1.05l.087.088c.35.175.788.175 1.05-.175l.263-.262a.93.93 0 0 0 .175-1.05l-.088-.088c-.612-1.05-.087-2.275 1.05-2.625h.088c.35-.087.7-.437.7-.875v-.35c0-.437-.262-.787-.7-.875ZM7 8.625c-.92 0-1.625-.704-1.625-1.625 0-.92.704-1.625 1.625-1.625.92 0 1.625.704 1.625 1.625 0 .92-.704 1.625-1.625 1.625Z"
      clip-rule="evenodd"
    />
  </svg>
</template>
