<template>
  <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7 14.0001C10.866 14.0001 14 10.8661 14 7.00006C14 3.13407 10.866 6.10352e-05 7 6.10352e-05C3.13401 6.10352e-05 0 3.13407 0 7.00006C0 10.8661 3.13401 14.0001 7 14.0001ZM3 7.00006C3 6.44778 3.44772 6.00006 4 6.00006H6V4.00006C6 3.44778 6.44772 3.00006 7 3.00006C7.55228 3.00006 8 3.44778 8 4.00006V6.00006H10C10.5523 6.00006 11 6.44778 11 7.00006C11 7.55235 10.5523 8.00006 10 8.00006H8V10.0001C8 10.5523 7.55228 11.0001 7 11.0001C6.44772 11.0001 6 10.5523 6 10.0001V8.00006H4C3.44772 8.00006 3 7.55235 3 7.00006Z"
      fill="currentColor"
    />
  </svg>
</template>
